<template>
  <div class="layoutBase">
    <el-container id="baseContent">
      <el-aside class="baseMenu" :width="collapsed ? '' : '200px'">
        <div class="logo" />
        <el-menu
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          @click="selectMenuItem"
          @openChange="openChange"
          :default-active="defaultSelectedKeys"
          :default-openeds="defaultOpenKeys"
          :theme="'dark'"
        >
          <template v-for="menu in menuList">
            <template v-if="menu.meta.noSubMenu">
              <el-menu-item
                v-if="user.userType == 1"
                :key="menu.name"
                :index="menu.name"
                @click="routeTo(`${menu.path}`)"
              >
                <i :class="[menu.meta.icon]"></i>
                {{ menu.meta.title }}
                <!-- <router-link :to="menu.redirect">
                                    <i :class="[menu.meta.icon]"></i>
                                    <a-icon :type="menu.meta.icon" />
                                    <span>
                                        {{menu.meta.title}}
                                    </span>
                                </router-link> -->
              </el-menu-item>
            </template>
            <template v-else>
              <el-submenu :key="menu.name" :index="menu.name">
                <template v-slot:title>
                  <span>
                    <!-- <a-icon :type="menu.meta.icon" /> -->
                    <i :class="[menu.meta.icon]"></i>
                    <span>{{ menu.meta.title }}</span>
                  </span>
                </template>
                <template v-for="menus in menu.children">
                  <el-menu-item
                    v-if="!menus.meta.noshow && ((menus.meta.auth || []).includes(user.userType) || (menus.meta.whiteList || []).includes(user.phone)) || (menus.meta.authName && user.userExtend.includes(menus.meta.authName))"
                    :key="menus.name"
                    :index="menus.name"
                    @click="routeTo(`${menu.path}/${menus.path}`)"
                  >
                    {{ menus.meta.title }}
                    <!-- <router-link :to="`${menu.path}/${menus.path}`">{{menus.meta.title}}</router-link> -->
                  </el-menu-item>
                </template>
              </el-submenu>
            </template>
          </template>
        </el-menu>
      </el-aside>
      <el-container class="content-box">
        <el-header style="background: #fff; padding: 0">
          <el-row type="flex" align="middle" style="height: 100%">
            <el-col :span="1">
            </el-col>
            <el-col :span="23" class="headeCont">
              <el-popover
                class="signOut"
                placement="bottom"
                trigger="click"
                :get-popup-container="getPopupContainer"
              >
                <!-- <template>
                  <p @click="userInfo" style="cursor: pointer">个人中心</p>
                </template> -->
                <template>
                  <p @click="signOut" style="cursor: pointer">退出登录</p>
                </template>
                <span class="user" slot="reference">
                  <el-row type="flex" align="middle" :gutter="20">
                    <el-col :span="12">
                      <el-avatar icon="user"></el-avatar>
                    </el-col>
                    <el-col :span="12">
                      {{ user.name }}
                    </el-col>
                  </el-row>
                </span>
              </el-popover>
            </el-col>
          </el-row>
        </el-header>
        <el-main class="layoutContent">
          <div class="container">
            <router-view class="router-view" />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "layoutBase",
  components: {
  },
  data() {
    return {
      collapsed: false,
      menuList: [],
      defaultOpenKeys: "",
      defaultSelectedKeys: "",
      transitionName: "vux-pop-out",
    };
  },
  watch: {
    $route() {
      const [, arr2] = this.$route.matched;
      // this.defaultOpenKeys = this.menuList.map(i => i.name);
      this.defaultSelectedKeys = arr2.name;
    },
  },
  computed: {
    ...mapState(["user", "pagesLoading"]),
  },
  created() {
    document.title = '创投后台管理系统'
    this.menuList = this.$router.options.routes.filter((item) => {
      return !item.meta.noshow && !item.meta.noAuth;
    });
    this.initMenuDefault();
  },
  methods: {
    ...mapMutations(["setUser"]),
    routeTo(path) {
      this.$router.push({ path });
    },
    selectMenuItem(val) {
      this.defaultSelectedKeys = val.key;
    },
    openChange(openKeys) {
      openKeys.length === 2
        ? (this.defaultOpenKeys = openKeys[1])
        : (this.defaultOpenKeys = "");
    },
    getPopupContainer(trigger) {
      return trigger;
    },
    initMenuDefault() {
      this.defaultOpenKeys = this.menuList.map(i => i.name);
      this.defaultSelectedKeys = this.$route.matched[1]["name"];
      if (this.$route.matched[0]["name"] == "") this.defaultSelectedKeys = "/";
    },
    signOut() {
      window.localStorage.setItem("user", "{}");
      this.setUser({});
      this.$router.replace({
        path: "/login",
      });
    },
    userInfo() {
      // this.$router.replace({
      //   path: "/login",
      // });
    }
  },
};
</script>
<style lang="less" scoped>
.layoutBase {
  height: 100vh;
  #baseContent {
    height: 100%;
    a {
      color: #fff;
    }
  }
  .baseMenu {
    background: #545c64;
  }
  .ant-layout-sider {
    background-image: url("./../../assets/menuBac.png");
    background-repeat: no-repeat;
    background-position: left bottom;
  }
  .layoutContent {
    // overflow: hidden;
    min-height: auto;
    .container {
      min-height: 100%;
      overflow: hidden;
      position: relative;
      /deep/ .tableCont {
        margin-top: 20px;
      }
    }
    .activeContainer {
      height: 100%;
    }
  }
  .headeCont {
    padding-right: 20px;
    box-sizing: border-box;
    // overflow: hidden;
    position: relative;
    .user {
      float: right;
      cursor: pointer;
      position: relative;
    }
    .signOut {
      /deep/ .ant-popover-inner-content {
        padding: 12px 0 !important;
        p {
          word-break: keep-all;
          margin: 0;
          padding: 4px 26px;
          cursor: pointer;
          &:hover {
            color: #46a6ff;
            background-color: #e8f4ff;
          }
        }
      }
    }
  }
  .router-view {
    width: 100%;
    // position: absolute;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    height: 100%;
  }
  .vux-pop-out-enter-active,
  .vux-pop-out-leave-active,
  .vux-pop-in-enter-active,
  .vux-pop-in-leave-active {
    will-change: transform;
    transition: all 500ms;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    backface-visibility: hidden;
    perspective: 1000;
  }
  .vux-pop-out-enter {
    opacity: 0;
    transform: translate3d(-15%, 0, 0);
  }
  .vux-pop-out-leave-active {
    opacity: 0;
    transform: translate3d(15%, 0, 0);
  }
  .vux-pop-in-enter {
    opacity: 0;
    transform: translate3d(15%, 0, 0);
  }
  .vux-pop-in-leave-active {
    opacity: 0;
    transform: translate3d(-15%, 0, 0);
  }
}
.content-box {
  background-color: #f1f5f7;
}
</style>
